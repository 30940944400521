import { createBrowserHistory } from "history";
import React from "react";
import "./App.scss";
import { QueryClientProvider, QueryClient } from "react-query";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

import FirebaseProvider from "providers/FirebaseProvider";
import UserProvider from "providers/UserProvider";
import RootWrapper from "wrappers/RootWrapper";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
    },
  },
});

export const history = createBrowserHistory();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <FirebaseProvider>
        <UserProvider>
          <HistoryRouter history={history}>
            <RootWrapper />
          </HistoryRouter>
        </UserProvider>
      </FirebaseProvider>
    </QueryClientProvider>
  );
}

export default App;
