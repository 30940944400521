import { Typography } from "antd";
import React from "react";

import { WindowRestoreIcon } from "assets/icon";

import styles from "./styles.module.scss";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.leftText}>
        <Typography.Link
          className="mr-20"
          href="https://landit-inc.notion.site/da3b566fd2284d11ad4872c62bbb4c67"
          target={"_blank"}
        >
          利用規約 <WindowRestoreIcon />
        </Typography.Link>
        <Typography.Link
          href="https://landit.co.jp/privacypolicy/"
          target={"_blank"}
        >
          プライバシーポリシー <WindowRestoreIcon />
        </Typography.Link>
      </div>
      <div className={styles.rightText}>
        &copy; {new Date().getFullYear()} Landit Inc.
      </div>
    </div>
  );
}
