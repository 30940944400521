import { Col, Divider, Modal, Row } from "antd";
import React from "react";

import { logout } from "api/axios";
import { SignOutIcon } from "assets/icon";
import { CommonButton } from "components/CommonButton";

import styles from "./styles.module.scss";

interface IProps {
  visible: boolean;
  profile: any;
  handleClose: () => void;
}

export const ProfileItem = ({
  title,
  value,
}: {
  title: string;
  value: any;
}) => {
  return (
    <Row gutter={10} className="mb-12">
      <Col span={10} className={styles.rowTitle}>
        {title}
      </Col>
      <Col span={14} className={styles.rowValue}>
        {value}
      </Col>
    </Row>
  );
};
export default function ModalProfile({
  visible,
  profile,
  handleClose,
}: IProps) {
  const handleLogout = () => {
    logout();
  };
  return (
    <Modal
      width={800}
      visible={visible}
      footer={null}
      title={null}
      closable={false}
      centered={true}
      className={styles.antModal}
    >
      <div className={styles.modalHeaderWrapper}>
        <div></div>
        <div onClick={handleLogout} className={styles.modalHeader}>
          <SignOutIcon />
          <span>ログアウト</span>
        </div>
      </div>

      <div className={styles.modalBody}>
        <div className={styles.title}>アカウント</div>
        <ProfileItem
          title="名前"
          value={`${profile?.lastName}${profile?.firstName}`}
        />
        <ProfileItem
          title="フリガナ"
          value={`${profile?.lastNameFurigana}${profile?.firstNameFurigana}`}
        />
        <ProfileItem title="携帯電話" value={`${profile?.phoneNumber}`} />
        <ProfileItem title="メールアドレス" value={`${profile?.email}`} />
        <div className="mb-50"></div>
        <ProfileItem
          title="会社名"
          value={`${profile?.companyUser?.company?.name}`}
        />
        <ProfileItem
          title="住所"
          value={`${profile?.companyUser?.company?.address}`}
        />
        <ProfileItem
          title="電話番号"
          value={`${profile?.companyUser?.company?.phoneNumber}`}
        />
        <ProfileItem
          title="請求先メールアドレス"
          value={`${profile?.companyUser?.company?.email}`}
        />

        <div className={styles.note}>
          <div className="mr-5">※ </div>
          <div>
            <div className={styles.text}>
              請求先を変更する場合は、カスタマーサポートまでご連絡
            </div>
            <div>ください</div>
            <div className={styles.phoneNumber}>03-5990-2831</div>
          </div>
        </div>
      </div>

      <Divider />
      <div className="flex align-center flex-column pb-30 pt-10">
        <CommonButton title="閉じる" type="default" onClick={handleClose} />
      </div>
    </Modal>
  );
}
