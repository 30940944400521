import { message } from "antd";
import Axios from "axios";
import { getAuth, signOut } from "firebase/auth";

import { history, queryClient } from "App";
import configs from "constants/config";

const customGetAuth = () => {
  const auth = getAuth();
  return auth;
};
const axiosInstance = Axios.create({
  timeout: 3 * 60 * 1000,
  baseURL: configs.API_DOMAIN,
});

axiosInstance.interceptors.request.use(
  async (config: any) => {
    // eslint-disable-next-line no-param-reassign
    const auth = customGetAuth();
    const token = await auth.currentUser?.getIdTokenResult();

    config.headers.Authorization = `Bearer ${token?.token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

export const logout = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      history.push("/login");
      queryClient.clear();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      message.destroy();
      message.error("セッションの有効期限が切れました。もう一度ログインしてください。", 5);
      return logout();
    }
  }
);

export const sendGet = (url: string, params?: any) => axiosInstance.get(url, { params }).then((res) => res.data);
export const sendPost = (url: string, params?: any, queryParams?: any) =>
  axiosInstance.post(url, params, { params: queryParams }).then((res) => res.data);
export const sendPut = (url: string, params?: any) => axiosInstance.put(url, params).then((res) => res.data);
export const sendPatch = (url: string, params?: any) => axiosInstance.patch(url, params).then((res) => res.data);
export const sendDelete = (url: string, params?: any) => axiosInstance.delete(url, { params }).then((res) => res.data);
