export enum PERMISSIONS {
  RESOURCE_MANAGEMENT = 1,
  LANGUAGE_MANAGEMENT = 2,
  MEMBER_MANAGEMENT = 3,
  NOTIFICATION_MANAGEMENT = 5,
  USER_MANAGEMENT = 6,
  ROLE_MANAGEMENT = 7,
}

export enum IsSystem {
  Yes = 1,
  No = 0,
}

export enum TypeInput {
  MULTIPLE = "multiple",
}

export enum TypePicker {
  TIME = "time",
  DATE = "date",
  MONTH = "month",
  YEAR = "year",
  DECADE = "decade",
}

export enum IsVisible {
  Yes = 1,
  No = 0,
}

export enum CommonStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum Role {
  CLIENT = 1,
  ADMIN = 2,
}

export enum RoutePath {
  REGISTER = "open-app",
  FORGOT_PASSWORD = "forgot-password",
  LOGIN = "login",
}

export enum AuthCodeType {
  REGISTER = 1,
  FORGOT_PASSWORD = 2,
  NEW_PASSWORD = 3,
}

export enum AuthCodeStatus {
  ACTIVE = 1,
  USED = 2,
  INACTIVE = 0,
}

export enum CompanyUserStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  NOT_VERIFY = 2,
  REJECT_VERIFY = 3,
  BLOCK = 4,
}

export enum UserStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  NOT_VERIFY = 2,
  REJECT_VERIFY = 3,
  SUSPEND = 4,
}

export enum ErrorUserType {
  REQUIRE = "require",
  TRIM = "trim",
  REGEX = "regex",
  MAX_LENGTH = "max_length",
  MIN_LENGTH = "min_length",
  OTHER = "other",
}

export enum ErrorImportCSV {
  EMAIL_ALREADY_EXIST = "Email_Already_Exist",
  DUPLICATE_EMAIL_ADDRESS = "Duplicate_Email_Address",
}
