import { useQuery } from "react-query";

import { getProfileUser } from "api/user";
import { QUERY_KEY } from "constants/queryKey";

export default function useProfile(enabled = false) {
  const { data: profile, refetch: refetchProfile } = useQuery<any>(
    QUERY_KEY.PROFILE,
    getProfileUser,
    { enabled }
  );
  return { profile: profile?.data, refetchProfile };
}
