import type { Analytics } from "firebase/analytics";
import type { Auth } from "firebase/auth";
import type { Firestore } from "firebase/firestore";
import type { FirebaseStorage } from "firebase/storage";
import React from "react";

const FirebaseContext = React.createContext(
  {} as {
    analytics: Analytics;
    firestore: Firestore;
    auth: Auth;
    storage: FirebaseStorage;
  }
);

export default FirebaseContext;
