import { Button, ButtonProps } from "antd";
import React from "react";

import styles from "./styles.module.scss";

interface IPropsButton extends ButtonProps {
  onClick?: (data?: any) => any;
  title?: any;
  type?: "default" | "primary";
  icon?: string;
}

export const CommonButton = ({
  onClick,
  title,
  icon,
  type = "default",
  ...rest
}: IPropsButton) => {
  return (
    <Button
      onClick={onClick}
      type={type}
      className={type === "default" ? styles.default : styles.primary}
      {...rest}
    >
      {title + " "}
    </Button>
  );
};
