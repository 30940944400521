const configs = {
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  AWS_DOMAIN: process.env.REACT_APP_AWS_DOMAIN,
  LANDIT_DOMAIN: process.env.REACT_APP_API_LANDIT,

  // firebase key
  apiKey: process.env.REACT_APP_PIT_PORT_API_KEY,
  appId: process.env.REACT_APP_PIT_PORT_APP_ID,
  authDomain: process.env.REACT_APP_PIT_PORT_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_PIT_PORT_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_PIT_PORT_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PIT_PORT_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PIT_PORT_STORAGE_BUCKET,
};

export default configs;
