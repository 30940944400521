import { ConfigProvider } from "antd";
import jaJP from "antd/lib/locale/ja_JP";
import React from "react";
import { createRoot } from "react-dom/client";
import "moment/locale/ja";

import App from "./App";
import "i18n/i18n";
import reportWebVitals from "./reportWebVitals";

ConfigProvider.config({
  theme: {
    primaryColor: "#00AEC4",
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <ConfigProvider
    locale={jaJP}
    autoInsertSpaceInButton={false}
    getPopupContainer={(trigger) => {
      if (trigger?.className?.includes("ant-select-selector") || trigger?.className?.includes("ant-picker"))
        return trigger?.parentNode;
      return document?.body as any;
    }}
  >
    <App />
  </ConfigProvider>
);

reportWebVitals();
