
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import configs from "constants/config";
import FirebaseContext from "contexts/FirebaseContext";

// PIT PORT
const pitPortApp = initializeApp({
  apiKey: configs.apiKey,
  appId: configs.appId,
  authDomain: configs.authDomain,
  measurementId: configs.measurementId,
  messagingSenderId: configs.messagingSenderId,
  projectId: configs.projectId,
  storageBucket: configs.storageBucket,
});
const analytics = getAnalytics(pitPortApp);
const auth = getAuth(pitPortApp);
auth.languageCode = "jp";
const firestore = getFirestore(pitPortApp);
const storage = getStorage(pitPortApp);

const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
  const value = { analytics, auth, firestore, storage };
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
