import axios from "axios";
import { Auth } from "firebase/auth";

import configs from "constants/config";
import { IUserCreateItem } from "constants/interface";

import { sendGet, sendPost, sendPut } from "./axios";

export const addUser = (params: any) => sendPost("/web/user/add-user", params);
export const addMultiUsersApi = (params: { companyId: number; users: IUserCreateItem[] }) =>
  sendPost("/web/user/import-users", params);
export const getListUser = (params: any) => sendGet("/web/user/list-user", params);
export const getDetailUser = (id: number) => sendGet(`/web/user/${id}`);
export const updateUser = (id: number, params: any) => sendPut(`/web/user/${id}`, params);
export const updateUserStatus = (params: any, userId?: number, companyId?: number) =>
  sendPut(`/web/user/${userId}/company/${companyId}/update-status`, params);

export const getProfileUser = () => sendGet("/web/user/profile");

export const getProfile = async (auth: Auth) => {
  const token = await auth?.currentUser?.getIdToken();

  if (!token) return [];
  const response = await axios
    .request({
      method: "get",
      url: `${configs.API_DOMAIN}/web/user/profile`,
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((e) => {
      throw e;
    });
  return response.data;
};

const BEARER_TOKEN =
  "Bearer " +
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NTQyNzQxMjIsImV4cCI6ODY0MTY1NDI3NDEyMn0.qxEPDKy906oCdcKrcBBePwiAQgeP4WFQijLobAaT0GE";
export const getListUserLandit = async (params: any) => {
  const response = await axios
    .request({
      method: "GET",
      url: `${configs.LANDIT_DOMAIN}/admin/user`,
      params,
      headers: {
        accept: "application/json",
        Authorization: BEARER_TOKEN,
      },
    })
    .catch((e) => {
      throw e;
    });

  return response.data;
};

export const VerifyUserLandit = async (userId: number, params: any) => {
  const result = await axios
    .request({
      method: "PUT",
      url: `${configs.LANDIT_DOMAIN}/admin/user/${userId}/verify`,
      data: params,

      headers: { Authorization: `Bearer ${BEARER_TOKEN}` },
    })
    .catch((e) => {
      throw e;
    });
  return result.status === 201;
};

export const UpdateUserStatus = async (userId: number, params: any) => {
  const result = await axios
    .request({
      method: "PUT",
      url: `${configs.LANDIT_DOMAIN}/admin/user/${userId}`,
      data: params,
      headers: { Authorization: `Bearer ${BEARER_TOKEN}` },
    })
    .catch((e) => {
      throw e;
    });
  return result.status === 201;
};

export const UpdateUserRole = async (params: any) => {
  const result = await axios
    .request({
      method: "POST",
      url: `${configs.LANDIT_DOMAIN}/admin/user/update-role`,
      data: params,
      headers: { Authorization: `Bearer ${BEARER_TOKEN}` },
    })
    .catch((e) => {
      throw e;
    });
  return result.status === 201;
};

export const detailLanditUser = async () => {
  const result = await axios
    .request({
      method: "GET",
      url: `${configs.LANDIT_DOMAIN}/admin/user/profile`,
      headers: { Authorization: `Bearer ${BEARER_TOKEN}` },
    })
    .catch((e) => {
      throw e;
    });
  return result?.data;
};

export const listContactBody = (pageSize: number, pageIndex: number) => ({
  url: `${configs.LANDIT_DOMAIN}/admin/contact?pageSize=${pageSize}&pageIndex=${pageIndex}`,
  method: "GET",
});

interface ParamsUser {
  pageSize: number;
  pageIndex: number;
  keyword?: string;
  status?: number;
}
export const listUserProxy = ({ pageIndex, pageSize }: ParamsUser) => ({
  url: `${configs.LANDIT_DOMAIN}/admin/user?pageSize=${pageSize}&pageIndex=${pageIndex}`,
  method: "GET",
});

export const updateStatusUser = (userId: number, status: number) => ({
  method: "PUT",
  url: `${configs.LANDIT_DOMAIN}/admin/user/${userId}`,
  body: {
    status,
  },
});

export const getProxy = async (params: any) => {
  const response = await axios
    .request({
      method: "POST",
      url: `${configs.LANDIT_DOMAIN}/proxy/call`,
      data: params,
      headers: {
        accept: "application/json",
        // Authorization: BEARER_TOKEN,
        userName: sessionStorage.getItem("userName") || "",
        password: sessionStorage.getItem("password") || "",
      },
    })
    .catch((e) => {
      throw e;
    });

  return response.data;
};
