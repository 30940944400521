import { Spin } from "antd";
import { lazy, Suspense, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { Navigate, Route, Routes } from "react-router-dom";

import { getProfileUser } from "api/user";
import PageHeader from "components/PageHeader";
import { CommonStatus } from "constants/enum";
import { QUERY_KEY } from "constants/queryKey";
import { ROUTES } from "constants/route";
import FirebaseContext from "contexts/FirebaseContext";
import UserContext from "contexts/UserContext";

import styles from "./styles.module.scss";

const History = lazy(() => import("pages/History"));
const User = lazy(() => import("pages/User"));
const CreateUser = lazy(() => import("pages/CreateUser"));
const EditUser = lazy(() => import("pages/EditUser"));
const ChangePassFirstTime = lazy(() => import("pages/ChangePasswordFirstTime"));

interface IChangePassProps {
  needToChangePassword: boolean;
  children: any;
}
const IsChangePasswordRoute = ({
  needToChangePassword,
  children,
}: IChangePassProps) => {
  if (needToChangePassword)
    return <Navigate to={`/${ROUTES.CHANGE_PASSWORD_FIST_TIME}`} replace />;

  return children;
};

export default function AuthWrapper() {
  const { isUserLoaded } = useContext(UserContext);
  const { auth } = useContext(FirebaseContext);

  const { data: profile } = useQuery<any>(QUERY_KEY.PROFILE, getProfileUser, {
    enabled: !!auth.currentUser,
  });

  const isNeedToChangePass = useMemo(
    () => profile?.data?.needToChangePassword === CommonStatus.ACTIVE,
    [profile]
  );

  if (!isUserLoaded) return <></>;
  if (!auth.currentUser) return <Navigate to={`/${ROUTES.LOG_IN}`} />;

  return (
    <div className={styles.mainWrapper}>
      <PageHeader />
      <div className={styles.pageContent}>
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        >
          <Routes>
            <Route
              path={`/${ROUTES.RESERVE}`}
              element={
                <IsChangePasswordRoute
                  needToChangePassword={isNeedToChangePass}
                >
                  <History />
                </IsChangePasswordRoute>
              }
            />
            <Route
              path={`/${ROUTES.USER}`}
              element={
                <IsChangePasswordRoute
                  needToChangePassword={isNeedToChangePass}
                >
                  <User />
                </IsChangePasswordRoute>
              }
            />
            <Route
              path={`/${ROUTES.CREATE_USER}`}
              element={
                <IsChangePasswordRoute
                  needToChangePassword={isNeedToChangePass}
                >
                  <CreateUser />
                </IsChangePasswordRoute>
              }
            />
            <Route
              path={`/${ROUTES.EDIT_USER}/:id`}
              element={
                <IsChangePasswordRoute
                  needToChangePassword={isNeedToChangePass}
                >
                  <EditUser />
                </IsChangePasswordRoute>
              }
            />
            <Route
              path={`/${ROUTES.CHANGE_PASSWORD_FIST_TIME}`}
              element={<ChangePassFirstTime />}
            />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}
