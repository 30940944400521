import { Menu } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  AvatarIcon,
  HistoryActiveIcon,
  HistoryIcon,
  UserActiveIcon,
  UserIcon,
} from "assets/icon";
import { Logo } from "assets/images";
import { ROUTES } from "constants/route";
import FirebaseContext from "contexts/FirebaseContext";
import useProfile from "utils/hooks/useProfile";

import ModalProfile from "./ModalProfile";
import styles from "./styles.module.scss";

export default function PageHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useContext(FirebaseContext);
  const { profile } = useProfile(!!auth.currentUser);

  const [selected, setSelected] = useState<any>(ROUTES.RESERVE);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const routes = [
    {
      key: "reserve",
      text: "利用履歴",
      url: `/${ROUTES.RESERVE}`,
      icon:
        selected === ROUTES.RESERVE ? <HistoryActiveIcon /> : <HistoryIcon />,
      visible: true,
    },
    {
      key: "user",
      text: "ユーザ一覧",
      url: `/${ROUTES.USER}`,
      icon: selected === ROUTES.USER ? <UserActiveIcon /> : <UserIcon />,
      visible: true,
    },
  ];

  useEffect(() => {
    if (location.pathname === "/") {
      const firstRoute = routes.find((x) => x.visible);
      navigate({
        pathname: firstRoute?.url || "/",
      });
    }

    routes.forEach((route: any) => {
      if (location.pathname.startsWith(route.url || "###")) {
        setSelected(route.key);
      }
    });

    if (
      location.pathname.includes(`/${ROUTES.CREATE_USER}`) ||
      location.pathname.includes(`/${ROUTES.EDIT_USER}`)
    ) {
      setSelected(ROUTES.USER);
    }
  }, [location.pathname]);

  const handleChangeMenu = (e: any) => {
    navigate(`/${e.key}`);
  };

  const items = routes.map((route) => ({
    label: route.text,
    key: route.key,
    icon: route.icon,
  }));

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerLeft}>
        <div className={styles.logo}>
          <img src={Logo} alt="" />
        </div>
        <Menu
          className={styles.menuHeader}
          mode="horizontal"
          defaultSelectedKeys={[""]}
          selectedKeys={[selected]}
          items={items}
          onClick={(e: any) => handleChangeMenu(e)}
        />
      </div>

      <div className={styles.headerRight} onClick={handleOpen}>
        <div className={styles.avatar}>
          <AvatarIcon />
        </div>

        <div className={styles.rightText}>
          <div className={styles.primaryText}>
            {profile?.lastName + profile?.firstName}
          </div>
          <div className={styles.subText}>
            {profile?.companyUser?.company?.name}
          </div>
        </div>
      </div>

      <ModalProfile
        profile={profile}
        visible={open}
        handleClose={handleClose}
      />
    </div>
  );
}
