import { useState, useContext, useEffect } from "react";

import FirebaseContext from "contexts/FirebaseContext";
import UserContext from "contexts/UserContext";


const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useContext(FirebaseContext);
  const [isUserLoaded, setIsUserLoaded] = useState(false);

  const value = { isUserLoaded };

  useEffect(() => {
    const unsubscribed = auth.onAuthStateChanged(() => {
      setIsUserLoaded(true);
    });
    return unsubscribed;
  }, [auth]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
