import { Spin } from "antd";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import Footer from "components/Footer";
import { ROUTES } from "constants/route";

import AuthWrapper from "./../AuthWrapper/index";
import styles from "./styles.module.scss";

const Login = lazy(() => import("pages/Login"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const NewPassword = lazy(() => import("pages/ForgotPassword/NewPassword"));
const RestoreUser = lazy(() => import("pages/RestoreUser"));
const UserLandit = lazy(() => import("pages/UserLandit"));
const OpenApp = lazy(() => import("pages/OpenApp"));
const Contact = lazy(() => import("pages/Contact"));

export default function RootWrapper() {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.body}>
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        >
          <Routes>
            <Route path={`/${ROUTES.LOG_IN}`} element={<Login />} />
            <Route
              path={`/${ROUTES.REQUEST_FORGOT_PASS}`}
              element={<ForgotPassword />}
            />
            <Route
              path={`/${ROUTES.FORGOT_PASSWORD}`}
              element={<NewPassword />}
            />
            <Route path={`/${ROUTES.RESTORE_USER}`} element={<RestoreUser />} />

            <Route path={`/${ROUTES.USER_LANDIT}`} element={<UserLandit />} />
            <Route path={`/${ROUTES.OPEN_APP}`} element={<OpenApp />} />
            <Route path={`/${ROUTES.CONTACT}`} element={<Contact />} />

            <Route path="/*" element={<AuthWrapper />} />
          </Routes>
        </Suspense>
      </div>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </div>
  );
}
