export const ROUTES = {
  LOG_IN: "login",
  FORGOT_PASSWORD: "forgot-password",
  REQUEST_FORGOT_PASS: "request-forgot-password",
  NEW_PASSWORD: "new-password",
  RESERVE: "reserve",
  USER: "user",
  CREATE_USER: "create-user",
  EDIT_USER: "edit-user",
  CHANGE_PASSWORD_FIST_TIME: "change-password-first-time",
  RESTORE_USER: "restore-user",
  USER_LANDIT: "user-landit",
  OPEN_APP: "open-app",
  CONTACT: "contact",
};
